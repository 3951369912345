<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="d-flex flex-wrap align-items-center justify-content-between mb-3"
        >
          <div class="d-flex align-items-center justify-content-between">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb p-0 mb-0">
                <li class="breadcrumb-item">
                  Customer View
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Add Car
                </li>
              </ol>
            </nav>
          </div>

          <router-link
            :to="{ name: 'layout.order' }"
            class="btn btn-secondary btn-sm d-flex align-items-center justify-content-between"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="ml-2">Back</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="row" id="main-div">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <form class="row g-3 date-icon-set-modal">
                  <div class="col-md-4 mb-3">
                    <label
                      for="car-make"
                      class="form-label font-weight-bold text-muted"
                      >Make *</label
                    >
                    <vue-single-select
                      v-model="makeId"
                      :options="vehicleMakes"
                      option-label="name"
                      option-key="id"
                      name="makeName"
                      value="id"
                      :getOptionDescription="getCustomDescription"
                      @input="onChangeCarMake($event)"
                    >
                    </vue-single-select>

                    <label
                      v-if="emptyMakeName"
                      for="Text1"
                      class="text-uppercase text-danger col-form-label-sm mt-1 ml-1"
                      >Please select car make</label
                    >
                  </div>

                  <div class="col-md-4 mb-3" v-if="makeId != null">
                    <label
                      for="car-model"
                      class="form-label font-weight-bold text-muted"
                      >Model *</label
                    >
                    <vue-single-select
                      v-model="modelID"
                      :options="vehicleModels"
                      option-label="name"
                      option-key="id"
                      name="model"
                      value="id"
                      :getOptionDescription="getCustomDescriptionModels"
                      @input="onChangeCarModel($event)"
                    >
                    </vue-single-select>
                    <label
                      v-if="emptyModelName"
                      for="Text2"
                      class="text-uppercase text-danger col-form-label-sm mt-1 ml-1"
                      >Please select car model</label
                    >
                  </div>
                  <div class="col-md-4 mb-3" v-if="modelID != null">
                    <label
                      for="Text3"
                      class="form-label font-weight-bold text-muted"
                      >Year *</label
                    >
                    <vue-single-select
                      v-model="year"
                      :options="years"
                      :getOptionDescription="getCustomDescriptionYears"
                    >
                    </vue-single-select>
                    <label
                      v-if="invalidEmptyYear"
                      for="Text3"
                      class="text-uppercase text-danger col-form-label-sm mt-1 ml-1"
                      >Year must be between</label
                    >
                  </div>
                  <div class="col-md-4 mb-3" v-if="modelID != null">
                    <label for="classification" class="form-label text-muted"
                      >Classification:</label
                    >
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      id="Text2"
                      placeholder="Enter Classification"
                      v-model="classification"
                      autocomplete="off"
                    />
                  </div>

                  <div class="col-md-4 mb-3">
                    <label for="license_no" class="form-label text-muted"
                      >Plate No:</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Text3"
                      placeholder="A Dubai 1234"
                      v-model="licensePlate"
                      autocomplete="off"
                      @keydown="handleLicensePlateNumber"
                    />
                    <label
                      v-if="emptyLicensePlateNumber"
                      for="Text2"
                      class="text-uppercase text-danger col-form-label-sm mt-1 ml-1"
                      >Please enter license plate number</label
                    >
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="vin" class="form-label text-muted"
                      >Vin No:</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Text4"
                      placeholder="Enter Vin"
                      v-model="vin"
                      autocomplete="off"
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Text9" class="form-label text-muted"
                      >Registration Renewal Date</label
                    >
                    <!-- <input type="text" class="form-control" id="Text9" placeholder="Enter Next Service Due Date"> -->
                    <b-form-datepicker
                      id="datepicker-registration-renewal-date"
                      v-model="registrationRenewalDate"
                      size="sm"
                      menu-class="w-100"
                      calendar-width="100%"
                      class="mb-2"
                      placeholder="Select Next Service Date"
                      @context="onContextRegistrationRenewalDate"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                    ></b-form-datepicker>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Text9" class="form-label text-muted"
                      >Insurance Renewal Date</label
                    >
                    <!-- <input type="text" class="form-control" id="Text9" placeholder="Enter Next Service Due Date"> -->
                    <b-form-datepicker
                      id="datepicker-insurance-renewal-date"
                      v-model="insuranceRenewalDate"
                      size="sm"
                      menu-class="w-100"
                      calendar-width="100%"
                      class="mb-2"
                      placeholder="Select Next Service Date"
                      @context="onContextInsuranceRenewalDate"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                    ></b-form-datepicker>
                  </div>

                </form>
                <form class="row g-3">
                    <div class="col-md-4 mb-3" id="app">
                      <label for="Text9" class="form-label text-muted"
                        >Registration Card Front</label
                      >
                      <div class="inputWrapper" style="width: 162px">
                        <button
                          class="btn btn-primary btn-sm"
                          style="font-size: medium;"
                          :disabled="front"
                        >
                          <svg
                            width="24"
                            height="24"
                            xmlns="http://www.w3.org/2000/svg"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            fill="white"
                            style="margin-right: 10px"
                            :disabled="front"
                          >
                            <path
                              d="M11.492 10.172l-2.5 3.064-.737-.677 3.737-4.559 3.753 4.585-.753.665-2.5-3.076v7.826h-1v-7.828zm7.008 9.828h-13c-2.481 0-4.5-2.018-4.5-4.5 0-2.178 1.555-4.038 3.698-4.424l.779-.14.043-.789c.185-3.448 3.031-6.147 6.48-6.147 3.449 0 6.295 2.699 6.478 6.147l.044.789.78.14c2.142.386 3.698 2.246 3.698 4.424 0 2.482-2.019 4.5-4.5 4.5m.978-9.908c-.212-3.951-3.472-7.092-7.478-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.522-5.408"
                            />
                          </svg>
                          Choose File
                          <input
                            class="fileInput hidden"
                            @change="handleFrontFileUpload"
                            type="file"
                            name="file3"
                            :disabled="front"
                          />
                        </button>
                      </div>
                      <br />
                      <div v-if="front">
                        <div class="divScroll" style="border: 1px solid grey">
                            <div style="display: flex; align-items: center">
                              <div class="mr-2 ml-1">{{ front.name }}</div>
                              <svg
                                clip-rule="evenodd"
                                fill="red"
                                width="18"
                                height="18"
                                fill-rule="evenodd"
                                stroke-linejoin="round"
                                stroke-miterlimit="2"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="removeFrontFile()"
                              >
                                <path
                                  d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                                />
                              </svg>
                            </div>
                          <!-- </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-3" id="app">
                      <label for="Text9" class="form-label text-muted"
                        >Registration Card Back</label
                      >
                      <div class="inputWrapper" style="width: 162px">
                        <button
                          class="btn btn-primary btn-sm"
                          style="font-size: medium;"
                          :disabled="back"
                        >
                          <svg
                            width="24"
                            height="24"
                            xmlns="http://www.w3.org/2000/svg"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            fill="white"
                            style="margin-right: 10px"
                            :disabled="back"
                          >
                            <path
                              d="M11.492 10.172l-2.5 3.064-.737-.677 3.737-4.559 3.753 4.585-.753.665-2.5-3.076v7.826h-1v-7.828zm7.008 9.828h-13c-2.481 0-4.5-2.018-4.5-4.5 0-2.178 1.555-4.038 3.698-4.424l.779-.14.043-.789c.185-3.448 3.031-6.147 6.48-6.147 3.449 0 6.295 2.699 6.478 6.147l.044.789.78.14c2.142.386 3.698 2.246 3.698 4.424 0 2.482-2.019 4.5-4.5 4.5m.978-9.908c-.212-3.951-3.472-7.092-7.478-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.522-5.408"
                            />
                          </svg>
                          Choose File
                          <input
                            class="fileInput hidden"
                            @change="handleBackFileUpload"
                            type="file"
                            name="file3"
                            :disabled="back"
                          />
                        </button>
                      </div>
                      <br />
                      <div v-if="back">
                        <div class="divScroll" style="border: 1px solid grey">
                            <div style="display: flex; align-items: center">
                              <div class="mr-2 ml-1">{{ back.name }}</div>
                              <svg
                                clip-rule="evenodd"
                                fill="red"
                                width="18"
                                height="18"
                                fill-rule="evenodd"
                                stroke-linejoin="round"
                                stroke-miterlimit="2"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="removeBackFile()"
                              >
                                <path
                                  d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                                />
                              </svg>
                            </div>
                          <!-- </div> -->
                        </div>
                      </div>
                    </div>                
                </form>
                <button
                  class="btn btn-success btn-sm"
                  id="add-car"
                  @click="createUserCar"
                >
                  Save Car
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="load-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_CAR_MAKE, GET_CAR_MODEL } from "../../../graphql/query";
import { ADD_WORKSHOP_USER_CAR } from "../../../graphql/mutation";

export default {
  name: "CarAdd",
  data() {
    return {
      vehicleMakes: [],
      makeId: null,
      vehicleModels: [],
      modelID: null,
      result: [],
      start: null,
      end: null,
      classification: "",
      year: null,
      licensePlate: "",
      userId: "",
      vin: null,
      errors: [],
      emptyMakeName: false,
      emptyModelName: false,
      emptyLicensePlateNumber: false,
      invalidEmptyYear: false,
      years: [],
      registrationRenewalDate: null,
      insuranceRenewalDate: null,
      isregistrationRenewalDate: false,

      front: null,
      back: null,
    };
  },
  apollo: {
    vehicleMakes: {
      query: GET_CAR_MAKE,
    },
  },
  props: ["number"],

  methods: {
    getCustomDescription(opt) {
      return opt.name;
    },
    getCustomDescriptionModels(opt) {
      return opt.name;
    },
    getCustomDescriptionYears(opt) {
      return opt;
    },

    onContextRegistrationRenewalDate(ctx) {
      this.registrationRenewalDate = ctx.selectedYMD;
      this.isregistrationRenewalDate = true
    },

    onContextInsuranceRenewalDate(ctx) {
      this.insuranceRenewalDate = ctx.selectedYMD;
    },

    async onChangeCarMake(selection) {
      if (selection == null) {
        this.modelID = null;
      } else {
        this.makeId = selection;
        this.emptyMakeName = false;
        this.$apollo
          .query({
            query: GET_CAR_MODEL,
            variables: {
              makeId: this.makeId.id,
            },
          })
          .then((data) => {
            this.vehicleModels = data.data.vehicleModels;
            if (document.getElementsByTagName) {
              var inputElements = document.getElementsByTagName("input");
              for (var i = 0; inputElements[i]; i++) {
                if (
                  inputElements[i].className &&
                  inputElements[i].className.indexOf("search-input") != -1
                ) {
                  inputElements[i].setAttribute("autocomplete", "off");
                }
              }
            }
          });
      }
    },

    onChangeCarModel(event) {
      this.modelID = event;
      this.emptyModelName = false;
      this.vehicleModels.filter((data) => {
        if (data.id === this.modelID.id) {
          this.years = data.years;
          this.classification = data.classification;
          return this.result;
        }
      });
    },

    handleLicensePlateNumber() {
      this.emptyLicensePlateNumber = false;
    },
    handleFrontFileUpload({ target }) {
      this.front = target.files[0];
    },
    removeFrontFile() {
      this.front = null;
    },
    handleBackFileUpload({ target }) {
      this.back = target.files[0];
    },
    removeBackFile() {
      this.back = null;
    },
    createUserCar() {
      if (this.makeId === null) {
        return (this.emptyMakeName = true);
      } 
      else if (this.modelID === null) {
        return (this.emptyModelName = true);
      } else if (this.year === null) {
        return (this.invalidEmptyYear = true);
      }
      else if (this.licensePlate === "") {
        return (this.emptyLicensePlateNumber = true);
      } 
      else if (this.back && this.front === null){
          this.$bvToast.toast("Registartion Card Front image is required", {
              toaster: "b-toaster-top-center",
              variant: "danger",
              solid: true,
              toastClass: "text-center toast-box toast-style",
            });
        } else {
        document.getElementById("add-car").disabled = true;
        document.getElementById("main-div").style.display = "none";
        document.getElementById("load-spinner").style.display = "flex";
        this.$apollo
          .mutate({
            mutation: ADD_WORKSHOP_USER_CAR,
            variables: {
              classification: this.classification,
              model: this.modelID.id,
              year: this.year,
              userId: this.number,
              licensePlate: this.licensePlate ? this.licensePlate : null,
              vin: this.vin,
              registrationRenewalAt: this.registrationRenewalDate
                ? this.registrationRenewalDate
                : null,
              insuranceRenewalAt: this.insuranceRenewalDate
                ? this.insuranceRenewalDate
                : null,
              
              registrationCardFront: this.front,
              registrationCardBack: this.back,
            },
          })
          .then((data) => {
            document.getElementById("main-div").style.display = "block";
            document.getElementById("load-spinner").style.display = "none";
            if (data.data.workshopUserCarCreate.errors.length === 0) {
              localStorage.setItem(
                "customer-car",
                data.data.workshopUserCarCreate.car.id
              );
              this.$router.push({ name:'order-add' });
              // this.$router.go(-1);
            } else {
              document.getElementById("add-car").disabled = false;
              this.errors = data.data.workshopUserCarCreate.errors;
              this.errors.map((err) => {
                if (err.message === "Year not in model.years.") {
                  return (this.invalidEmptyYear = true);
                }
              });
            }
          });
      }
    },
  },
};
</script>
<style>
.b-toast-success.b-toast-solid .toast.toast-box,
.b-toast-danger.b-toast-solid .toast.toast-box {
  padding: 10px;
  border-radius: 3px;
}

.custom-input {
  display: block;
  width: 100%;
  padding: 0.375em 0.75em;
  font-size: 1em;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25em;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
}
.inputWrapper {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  /*This makes the button huge so that it can be clicked on*/
  font-size: 50px;
}
.hidden {
  /*Opacity settings for all browsers*/
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}
.divScroll {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  padding: 15px;
}
.img-tag {
  padding: 5px;
  margin: 3px;
  background-color: #80808038;
  border-radius: 15px;
}
.btn-primary.disabled, .btn-primary:disabled{
  color: #fff;
  background-color: #516ea4;
  border-color: #516ea4;
}
.fileInput:disabled{
  cursor: not-allowed;
}

#load-spinner {
  justify-content: center;
  display: none;
}
</style>
